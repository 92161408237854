import * as yup from "yup";
import {COURSE_TYPE} from "./constants/back-office-constants";

export const DISABLED_CONDITION = {   
  "course.secondaryUniversities": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        courseType !== COURSE_TYPE.joint &&
        courseType !== COURSE_TYPE.multi
      );
    },
    required: function (courseType) {
      // return (
      //   !!courseType &&
      //   [COURSE_TYPE.joint, COURSE_TYPE.multi].includes(courseType)
      // );
      return false;
    },
  },
  "course.code": {
    disabled: function (courseType) {
      return !!courseType && courseType === COURSE_TYPE.additional;
    },
    required: function (courseType) {
      return !!courseType && courseType !== COURSE_TYPE.additional;
    },
  },
  "course.pkey": {
    disabled: function (courseType) {
      return !!courseType && courseType === COURSE_TYPE.additional;
    },
    required: function (courseType) {
      return !!courseType && courseType !== COURSE_TYPE.additional;
    },
  },
  "course.multiJoinCode": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        courseType !== COURSE_TYPE.joint &&
        courseType !== COURSE_TYPE.multi
      );
    },
    required: function (courseType) {
      return (
        !!courseType &&
        [COURSE_TYPE.joint, COURSE_TYPE.multi].includes(courseType)
      );
    },
  },
  // "course.leader": {
  //   disabled: function (courseType) {
  //     return (
  //       !!courseType &&
  //       courseType !== COURSE_TYPE.joint &&
  //       courseType !== COURSE_TYPE.multi
  //     );
  //   },
  // },
  "course.period": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        courseType === COURSE_TYPE.additional 
      );
    },
    required: function (courseType) {
      return (
        !!courseType &&
        ![COURSE_TYPE.additional].includes(courseType)
      );
    },
  },
  "course.openToRegistration": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        ![COURSE_TYPE.open, COURSE_TYPE.skills].includes(courseType)
      );
    },
    required: function (courseType) {
      !!courseType &&
        [COURSE_TYPE.open, COURSE_TYPE.skills].includes(courseType);
    },
  },
  "course.maxRegistration": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        ![COURSE_TYPE.open, COURSE_TYPE.skills].includes(courseType)
      );
    },
  },
  "course.registrationDeadline": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        ![COURSE_TYPE.open, COURSE_TYPE.skills].includes(courseType)
      );
    },
  },
  "course.subtype": {
    disabled: function (courseType) {
      return !!courseType && COURSE_TYPE.additional !== courseType;
    },
    required: function (courseType) {
      return !!courseType && courseType === COURSE_TYPE.additional;
    },
  },
  "course.engageCourse": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        [
          COURSE_TYPE.skills,
          COURSE_TYPE.additional,
          COURSE_TYPE.other,
        ].includes(courseType)
      );
    },
  },
  "course.title": {},
  "course.shortDescription": {
    required: function (courseType) {
      return !!courseType && courseType !== COURSE_TYPE.additional;
    },
  },
  "course.descriptionLanguage": {
    required: function (courseType) {
      return !!courseType && courseType !== COURSE_TYPE.additional;
    },
  },
  "course.teacher": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        [COURSE_TYPE.joint, COURSE_TYPE.multi,COURSE_TYPE.additional].includes(courseType)
      );
    },
  },
  "course.links": {
    required: function (courseType) {
      return !!courseType && courseType === COURSE_TYPE.additional;
    },
  },
  "course.courseLanguage": {
    required: function (courseType) {
      return !!courseType && courseType !== COURSE_TYPE.additional;
    },
  },
  "course.academicEmail": {
    disabled: function (courseType) {
      return !!courseType && courseType === COURSE_TYPE.other;
    },
  },
  "course.helpDeskEmail": {
    disabled: function (courseType) {
      return !!courseType && courseType === COURSE_TYPE.other;
    },
  },
  session: {
    disabled: function (courseType) {
      return (
        !!courseType &&
        [COURSE_TYPE.additional, COURSE_TYPE.other].includes(courseType)
      );
    },
  },
  "session.teachers": {
    disabled: function (courseType) {
      return (
        !!courseType &&
        ![COURSE_TYPE.joint, COURSE_TYPE.multi].includes(courseType)
      );
    },
  },
};

export const courseSchema = yup.object().shape({
  type: yup.string().required("error.field.required"),
  title: yup.string().required("error.field.required"),
  university: yup.string().required("error.field.required"),
  logo: yup.string().required("error.field.required"),
  cycles: yup.array().min(1, "error.field.required"),
  code: yup.string().when(["type"], {
    is: (type) => DISABLED_CONDITION["course.code"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  key: yup.string().when(["type"], {
    is: (type) => DISABLED_CONDITION["course.pkey"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  multiJoinCode: yup.string().when(["type"], {
    is: (type) => DISABLED_CONDITION["course.multiJoinCode"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  period: yup.string().when(["type"], {
    is: (type) => DISABLED_CONDITION["course.period"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  academicEmail: yup.string().email("error.field.invalid.email").nullable(),
  helpDeskEmail: yup.string().email("error.field.invalid.email").nullable(),
  openToRegistration: yup.string().when(["type"], {
    is: (type) =>
      DISABLED_CONDITION["course.openToRegistration"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  subtype: yup.string().when(["type"], {
    is: (type) => DISABLED_CONDITION["course.subtype"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  shortDescription: yup.string().when(["type"], {
    is: (type) => DISABLED_CONDITION["course.shortDescription"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  language: yup.string().when(["type"], {
    is: (type) =>
      DISABLED_CONDITION["course.descriptionLanguage"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  courseLanguage: yup.string().when(["type"], {
    is: (type) =>
      DISABLED_CONDITION["course.courseLanguage"].required(type),
    then: yup.string().required("error.field.required"),
  }),
  links: yup.array().when(["type"], {
    is: (type) => {
      return DISABLED_CONDITION["course.links"].required(type);
    },
    then: yup.array().of(
      yup.object().shape({
        description: yup.string().required("error.field.required"),
        type: yup.string().required("error.field.required"),
        url: yup.string().required("error.field.required"),
      })
    ),
    otherwise: yup.array(),
  }),
  teachers:  yup.array().when(["type"], {
    is: (type) => {
      return DISABLED_CONDITION["course.links"].required(type);
    },
    then:yup.array().of(
    yup.object().shape({
      email: yup.string().email("error.field.invalid.email").nullable()
    })),
    otherwise: yup.array(),
  })
});

export const sessionSchema = yup.object().shape({
  sessions: yup.array().of(
    yup.object().shape({
      title: yup.string().required("error.field.required"),
      startDate: yup.string().nullable().required("error.field.required"),
      endDate: yup.string().nullable().required("error.field.required")
      .when("startDate", (st, schema) => {
        if (st) {
          return yup.date().min(st, "error.end.befor.start");
        }
      }),
      teachers: yup.array().of(
        yup.object().shape({
          email: yup.string().email("error.field.invalid.email").nullable()
        })
      )
    }),
  )
});
